import { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { segmentPage } from '@utils/analytics';
import { UserContext } from '@contexts/user-context';
import { saveUTMParams } from '@utils/utm';

const propTypes = {
  pageName: PropTypes.string.isRequired,
  url: PropTypes.string,
  path: PropTypes.string,
  otherProps: PropTypes.shape({}),
  router: PropTypes.shape({
    asPath: PropTypes.string,
    query: PropTypes.shape({}),
  }),
};

const defaultProps = {
  path: null,
  url: null,
  otherProps: {},
  router: {
    asPath: '/',
    query: {},
  },
};

const SegmentPage = ({ pageName, url, path, otherProps, router }) => {
  const { isUserIdentified } = useContext(UserContext);
  const [eventFired, setEventFired] = useState(false);

  useEffect(() => {
    if ((process.browser || global.isTestRuning) && isUserIdentified && !eventFired) {
      saveUTMParams(router.query);
      segmentPage(pageName, {
        title: pageName,
        url: url || window.location.href,
        path: path || window.location.pathname,
        ...otherProps,
      });
      setEventFired(true);
      const containsVideoObj = window.dataLayer.filter((obj) =>
        // eslint-disable-next-line no-prototype-builtins
        obj.hasOwnProperty('contains_video'),
      );
      if (containsVideoObj.length > 0) {
        containsVideoObj[0].contains_video = !!otherProps?.contains_video;
      } else {
        window.dataLayer.push({ contains_video: !!otherProps?.contains_video });
      }
    }
  }, [isUserIdentified, eventFired, pageName, url, path, otherProps, router.query]);

  return null;
};

SegmentPage.propTypes = propTypes;
SegmentPage.defaultProps = defaultProps;

export default withRouter(SegmentPage);
