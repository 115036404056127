import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { truncate } from '@utils/index';

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  dynamicLinkTags: PropTypes.arrayOf(
    PropTypes.shape({
      rel: PropTypes.string,
      href: PropTypes.string,
    }),
  ),
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
  twitterImage: PropTypes.string,
  canonicalUrl: PropTypes.string,
  robots: PropTypes.string,
};

const defaultProps = {
  title: 'Wizarding World - Official home of Harry Potter & Fantastic Beasts.',
  description:
    'Official home of Harry Potter & Fantastic Beasts. Discover your Hogwarts house, wand and Patronus, play quizzes, read features, and keep up to speed on the latest Wizarding World news.',
  dynamicLinkTags: [],
  ogTitle: '',
  ogDescription: '',
  ogImage: '',
  twitterImage: '',
  canonicalUrl: '',
  robots: '',
};

export const correctTitleAndDescription = (title, description) => {
  let cTitle = title;
  let cDescription = description;
  if (title === '' || title === null) {
    cTitle = defaultProps.title;
  }
  if (description === '' || description === null) {
    cDescription = defaultProps.description;
  }
  return {
    cTitle,
    cDescription,
  };
};

const HeadMeta = ({
  title,
  description,
  dynamicLinkTags,
  ogTitle,
  ogDescription,
  ogImage,
  twitterImage,
  canonicalUrl,
  robots,
}) => {
  const { cTitle, cDescription } = correctTitleAndDescription(title, description);
  const optimalMetaDescriptionLength = 165;
  const metaDescription = truncate(cDescription, optimalMetaDescriptionLength);

  const processUrl = () => {
    if (canonicalUrl.indexOf('http://') === 0 || canonicalUrl.indexOf('https://') === 0) {
      return `${canonicalUrl}`;
    }

    // REACT_APP_SSR_URI_OVERRIDE maps to the definitive site domain at that moment.
    // If an override url is set in SSM then use that. This will be used during the
    // wizardingworld.com -> harrypotter.com switch to ensure that canonical
    // urls are the same across both sites.
    const canonicalUrlRoot =
      process.env.REACT_APP_SSR_URI_OVERRIDE || process.env.REACT_APP_SSR_URI;

    return `${canonicalUrlRoot}${canonicalUrl}`;
  };
  const processedCanonicalUrl = processUrl();

  return (
    <Head>
      <title>{cTitle}</title>
      <meta key="description" name="description" content={metaDescription} />
      <meta
        name="google-site-verification"
        content={process.env.REACT_APP_GOOGLE_SITE_VERIFICATION_ID}
      />
      {cTitle === defaultProps.title && process.env.REACT_APP_SMART_BANNER === 'TRUE' && (
        <meta name="apple-itunes-app" content="app-id=1427926466" />
      )}
      {dynamicLinkTags.map(({ rel, href }) => (
        <link key={href} rel={rel} href={href} />
      ))}
      <meta property="og:title" key="og:title" content={ogTitle || cTitle} />
      <meta
        property="og:description"
        key="og:description"
        content={ogDescription || cDescription}
      />
      <meta property="og:image" content={ogImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ogTitle || cTitle} />
      <meta name="twitter:description" content={ogDescription || cDescription} />
      <meta name="twitter:image" content={twitterImage} />
      {canonicalUrl && <link rel="canonical" href={processedCanonicalUrl} />}
      {robots && <meta name="robots" content={robots} />}
    </Head>
  );
};

HeadMeta.propTypes = propTypes;
HeadMeta.defaultProps = defaultProps;
export default HeadMeta;
