import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './ShopRowSkeleton.module.scss';

const propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
  type: PropTypes.oneOf(['multiple', 'single']),
  shopData: PropTypes.shape({}),
  shopLink: PropTypes.bool,
};

const defaultProps = {
  theme: 'dark',
  type: 'multiple',
  shopData: {
    title: '',
    description: '',
  },
  shopLink: false,
};

const ShopRowSkeleton = ({ theme, type, shopData, shopLink }) => {
  const { title, description } = shopData;
  if (type === 'single') {
    return (
      <div
        className={cx(s.shopRowSkeleton, s[theme], s.singleItem)}
        data-testid="singleItemSkeleton"
      >
        <div className={s.header}>
          <div className={s.left}>
            {title && (
              <>
                <div className={cx(s.title, s.shiningElement)} />
                <div className={cx(s.title, s.shiningElement)} />
              </>
            )}
            {description && (
              <>
                <div className={cx(s.description, s.shiningElement)} />
                <div className={cx(s.description, s.shiningElement)} />
                <div className={cx(s.description, s.shiningElement)} />
              </>
            )}
          </div>
          <div className={s.right}>
            {shopLink && <div className={cx(s.shopLink, s.shiningElement)} />}
          </div>
        </div>
        <div className={s.itemsCarousel}>
          <div className={s.itemContainer}>
            <div className={s.image} />
            <div className={s.textContainer}>
              <div className={cx(s.line1, s.shiningElement)} />
              <div className={cx(s.line1, s.shiningElement)} />
              <div className={cx(s.line2, s.shiningElement)} />
              <div className={cx(s.line3, s.shiningElement)} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cx(s.shopRowSkeleton, s[theme])} data-testid="multipleItemSkeleton">
      <div className={s.header}>
        <div className={s.left}>
          {title && (
            <>
              <div className={cx(s.title, s.shiningElement)} />
              <div className={cx(s.title, s.shiningElement)} />
            </>
          )}
          {description && (
            <>
              <div className={cx(s.description, s.shiningElement)} />
              <div className={cx(s.description, s.shiningElement)} />
              <div className={cx(s.description, s.shiningElement)} />
            </>
          )}
        </div>
        <div className={s.right}>
          {shopLink && <div className={cx(s.shopLink, s.shiningElement)} />}
        </div>
        <div className={s.carouselArrows}>
          <span className={cx(s.leftArrow, s.shiningElement)} />
          <span className={cx(s.dots, s.shiningElement)} />
          <span className={cx(s.rightArrow, s.shiningElement)} />
        </div>
      </div>
      <div className={s.itemsCarousel}>
        <div className={s.itemContainer}>
          <div className={s.image} />
          <div className={s.textContainer}>
            <div className={cx(s.line1, s.shiningElement)} />
            <div className={cx(s.line2, s.shiningElement)} />
            <div className={cx(s.line3, s.shiningElement)} />
          </div>
        </div>
        <div className={s.itemContainer}>
          <div className={s.image} />
          <div className={s.textContainer}>
            <div className={cx(s.line1, s.shiningElement)} />
            <div className={cx(s.line2, s.shiningElement)} />
            <div className={cx(s.line3, s.shiningElement)} />
          </div>
        </div>
        <div className={s.itemContainer}>
          <div className={s.image} />
          <div className={s.textContainer}>
            <div className={cx(s.line1, s.shiningElement)} />
            <div className={cx(s.line2, s.shiningElement)} />
            <div className={cx(s.line3, s.shiningElement)} />
          </div>
        </div>
        <div className={s.itemContainer}>
          <div className={s.image} />
          <div className={s.textContainer}>
            <div className={cx(s.line1, s.shiningElement)} />
            <div className={cx(s.line2, s.shiningElement)} />
            <div className={cx(s.line3, s.shiningElement)} />
          </div>
        </div>
        <div className={s.itemContainer}>
          <div className={s.image} />
          <div className={s.textContainer}>
            <div className={cx(s.line1, s.shiningElement)} />
            <div className={cx(s.line2, s.shiningElement)} />
            <div className={cx(s.line3, s.shiningElement)} />
          </div>
        </div>
      </div>
    </div>
  );
};

ShopRowSkeleton.propTypes = propTypes;
ShopRowSkeleton.defaultProps = defaultProps;
export default ShopRowSkeleton;
