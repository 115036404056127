import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useGlobalState as useNavigationGlobalState } from '@/src/globalStates/NavigationState';
import { stripLocaleFromURL } from '@/src/utils/analytics';
import s from './MyLayout.module.scss';
import { useNavContext } from '../../contexts/nav-context';
import Header from '../Header';
import Footer from '../Footer';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  theme: PropTypes.oneOf([
    'dark',
    'light',
    'medium',
    'fan-club',
    'gryffindor',
    'slytherin',
    'hufflepuff',
    'ravenclaw',
    'campaign',
    'noHouse',
    'user-ravenclaw',
    'user-hufflepuff',
    'user-gryffindor',
    'user-slytherin',
    'user-no-house',
    'discover',
  ]),
};

const defaultProps = {
  children: <></>,
  className: null,
  noFooter: false,
  noHeader: false,
  theme: 'dark',
};

const Layout = ({ children, className, noFooter, noHeader, theme }) => {
  const [blur] = useNavigationGlobalState('blur');
  const [navRibbonActive] = useNavigationGlobalState('navRibbonActive');
  const { navData, footerData } = useNavContext();
  const [showNavigationSpacer, setShowNavigationSpacer] = useState(false);

  useEffect(() => {
    const currentPath = stripLocaleFromURL(window.location.pathname);
    const ignorePaths = ['/portrait-maker'];
    setShowNavigationSpacer(!ignorePaths.includes(currentPath));
  }, []);

  return (
    <div className={cx(className, theme)}>
      {navData && !noHeader && <Header navData={navData} />}
      <div data-testid="blurContainer" className={cx(s.blurContainer, blur ? s.blur : '')}>
        <>
          {showNavigationSpacer && (
            <div
              className={[s.navigationSpacer, navRibbonActive ? s.ribbonActive : ''].join(' ')}
            />
          )}
        </>
        {children}
        {footerData && !noFooter && <Footer footerData={footerData} />}
      </div>
    </div>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;
export default Layout;
