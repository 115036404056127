import React from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from '../../contexts/user-context';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const IsOverAge = ({ children }) => {
  const userContext = useUserContext();
  const isUnderAge = userContext?.profile?.underage ?? true;

  return isUnderAge ? null : <>{children}</>;
};

IsOverAge.propTypes = propTypes;
export default IsOverAge;
